.timeoffColumn {
  min-width: 230px !important;
  padding-left: 20px !important;
  padding-right: 10px !important;
}

.timeoffDays {
  font-size: 26px;
}

.timeoffHours {
  font-size: 14px;
  margin-left: 5px;
}

.timeoffButtonContainer1 {
  margin: auto !important;
  width: 211px !important;
  height: 38px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.timeoffButtonContainer2 {
  margin: auto !important;
  width: 211px !important;
  height: 38px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.timeoffButtonContainer3 {
  margin: auto !important;
  width: 220px !important;
  height: 38px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

@media (min-width: 474px) {
  .timeoffButtonContainer1 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .timeoffButtonContainer2 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
}

@media (min-width: 576px) {
  .timeoffButtonContainer1 {
    margin-left: 19px !important;
  }
}

.people-subtitleOld {
  font-family: "nw-primary";
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #4d4f53;
}
