@media (max-width: 575px) {
  .sc-label {
    max-width: 220px;
  }
}
.fowMobileSize {
  display: block;
}
.fowMobileSize .col {
  text-align: center;
}
.fowBrowserSize {
  display: none;
}

.fowButtonContainer1 {
  margin: auto !important;
  width: 200px !important;
  height: 38px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.fowButtonContainer2 {
  margin: auto !important;
  width: 207px !important;
  height: 38px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

@media (min-width: 576px) {
  .fowMobileSize {
    display: none;
  }
  .fowBrowserSize {
    display: block;
  }
  .fowButtonContainer1 {
    margin-left: 19px !important;
  }
}
