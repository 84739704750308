.jobsColumn {
  min-width: 234px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.jobsButtonContainer1 {
  margin: auto !important;
  width: 115px !important;
  height: 38px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.jobsButtonContainer2 {
  margin: auto !important;
  width: 141px !important;
  height: 38px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.jobsButtonContainer3 {
  margin: auto !important;
  width: 133px !important;
  height: 38px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.activeAppsValue {
  font-size: 25px;
  margin-left: 10px;
  padding-top: 10px;
}
.openJobsValue {
  font-size: 25px;
  margin-left: 10px;
  padding-top: 10px;
}
@media (min-width: 491px) {
  .activeAppsValue {
    margin-left: 0px !important;
  }
}

@media (min-width: 576px) {
  .jobsButtonContainer1 {
    margin-left: 19px !important;
  }
}

@media (min-width: 474px) {
  .jobsButtonContainer1 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .jobsButtonContainer2 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .jobsButtonContainer3 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
}
