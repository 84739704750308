.myPayColumn {
  min-width: 234px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.myPayButtonContainer1 {
  margin: auto !important;
  width: 175px !important;
  height: 38px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.myPayButtonContainer2 {
  margin: auto !important;
  width: 222px !important;
  height: 38px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

@media (min-width: 576px) {
  .myPayButtonContainer1 {
    margin-left: 19px !important;
  }
}

@media (min-width: 474px) {
  .myPayButtonContainer1 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .myPayButtonContainer2 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
}
