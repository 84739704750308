.newHireTitleBar {
  background-color: #1c57a5;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  min-height: 49px;
}

.newHireCell {
  background-color: white;
  /* height: 100%; */
  border-radius: 12px 12px 12px 12px;
  padding: 0px !important;
}

.newHiresCloseButton {
  background-color: transparent !important;
  background-image: url(/newHiresClose.png);
  height: 34px;
  width: 34px;
  position: absolute;
  top: 4px;
  left: calc(100% - 54px);
}

.newHireMobilePopupContainer {
  font-family: "nw-primary";
  z-index: 500;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #1c57a5;
  border-radius: 12px 12px 12px 12px;
  display: block;
  padding: 3px;
  width: 100%;
  height: 100%;
  overflow-x: auto;
}
.newHiresMobileContainer {
  min-height: 189px;
  margin: 3px;
  display: block;
  background-color: white;
}

.newHiresContainer {
  overflow: hidden auto;
  height: 324px;
  min-height: 189px;
  /* margin: 3px; */
  display: block;
  position: relative;
}
.newHiresContainerPadding {
  border-top: #bcbdbc solid 1px;
  margin-left: 25px;
  margin-right: 25px;
  padding-top: 16px;
}

.newHireTitle {
  display: inline-block;
  color: white;
  font-size: 20px;
  line-height: 24px;
  width: 255px;
  padding-left: 20px;
  padding-top: 8px;
}
.newHireSubTitle {
  color: white;
  font-size: 14px;
  line-height: 17px;
  width: 100%;
}
.newHireDescriptionTitle {
  color: #003b5c;
  font-size: 18px;
  line-height: 17px;
}
.newHireDescription {
  font-size: 14px;
  line-height: 17px;
  color: #4d4f53;
}

.newHiresFilterTitle {
  font-size: 14px;
  color: #4d4f53;
  line-height: 17px;
}

.newHiresPopupContainer {
  font-family: "nw-primary";
  z-index: 300;
  position: fixed;
  height: 600px;
  width: 300px;
  top: 10px;
  left: 50%;
  /* margin: -365px 0 0 -150px; */
  margin-left: -150px;
  background-color: #1c57a5;
  border-radius: 12px 12px 12px 12px;
  display: block;
  padding: 3px;
}

.newHiresContainer::-webkit-scrollbar {
  width: 16px;
}

.newHiresContainer::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  /* border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px; */
}

.newHiresContainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px; */
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background: #1553a5;
}
.newHiresDataContainer {
  display: inline-block;
  font-size: 14px;
  /* width: 241px; */
  width: calc(100% - 21px);
}

.teamMemberImage {
  height: 75px;
  border-radius: 12px;
  border-color: #1c57a5;
  border-style: solid;
  transition: all 0.3s ease-out;
  /* cursor: pointer; */
  /* margin-left: 5px;
        margin-right: 12px; */
}
/* .teamMemberImage:hover {
      transform: scale(1.1);
    } */

.filterButton {
  padding-top: 1px !important;
  background-color: #daf2fa !important;
  border: 1px solid #44bce4 !important;
  border-radius: 6px;
  height: 70px;
  font-family: "nw-primary" !important;
  font-weight: bold !important;
  font-size: 10px !important;
  letter-spacing: 0px;
  color: #1c57a5 !important;
  border-radius: 6px;
  width: 100%;
}

.filterButtonContainer1 {
  display: inline-block;
  width: 74px;
}
.filterButtonContainer2 {
  display: inline-block;
  width: 74px;
}

.modalImage {
  border-color: #1c57a5 !important;
  border-style: solid !important;
  border-width: 4px !important;
  border-radius: 12px !important;
}

.newHireLabel {
  display: inline-block;
  /* width: 120px; */
  padding-left: 10px;
  font-size: 11px;
  line-height: 17px;
  font-family: "nw-primary" !important;
  font-weight: 600 !important;
  color: black;
  text-transform: uppercase;
}
.newHireValue {
  display: inline-block;
  padding-left: 10px;
  font-size: 14px;
  color: black;
  font-weight: 400;
}
.newHireHeading {
  display: inline-block;
  padding-left: 10px;
  font-size: 1.1rem;
  color: black;
  font-weight: 400;
}

.newHiresHeading {
  /* display: grid;
      grid-template-columns: auto 317px; */
  width: calc(100% - 20px);
  font-family: "nw-primary";
  font-size: 1.1rem;
  margin: 10px;
  position: relative;
  top: 0;
  left: 0;
}

.newHireLocationSelect {
  display: inline-block;
  font-family: "nw-primary";
  font-size: 14px;
  color: #4d4f53;
  background-color: white;
  height: 31px;
  padding-left: 13px;
  line-height: 17px;
  z-index: 251;
  padding-top: 5px;
  border-bottom: #707070 solid 1px;
  margin-top: 15px;
  background: #f6f6f6 0% 0% no-repeat padding-box;
}
.newHireLocationSelect > button {
  width: 257px;
  text-align: left;
  border: none;
  background-color: transparent;
  background-image: url("/chevron_down.png");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: center;
  font-weight: bold !important;
}
.newHireHeadingC1 {
  display: inline-block;
  max-width: 100%;
  /* border: red solid 1px; */
  vertical-align: top;
}
.newHireHeadingC2 {
  display: inline-block;
  width: 100%;
  /* border: red solid 1px; */
  height: 100%;
  vertical-align: top;
}
.newHiresFilterButtonText {
  overflow: hidden;
  height: 20px;
  width: 218px;
  white-space: nowrap;
}

.newHiresLocations {
  position: absolute;
  top: 249px;
  z-index: 200;
  right: 14px;
  border: black solid 2px;
  overflow-y: auto;
  max-height: 400px;
  border-radius: 0 0 15px 15px;
  width: 273px;
  font-family: "nw-primary";
  font-size: 14px;
}

@media screen and (min-width: 400px) {
  .filterButtonContainer1 {
    width: 15%;
  }
  .filterButtonContainer2 {
    width: 20%;
  }
  .newHiresPopupContainer {
    /* margin: -200px 0 0 -200px; */
    margin-left: -200px;
    width: 400px;
  }
  /* .newHiresDataContainer {
        width: 255px;
      } */
  /* .newHireTitle {
    width: 345px;
  } */
  .newHireTitle {
    width: calc(100% - 5px);
  }
  .newHireLocationSelect > button {
    width: 250px;
  }

  .newHiresLocations {
    top: 188px;
    width: 266px;
    right: 121px;
  }

  .newHiresContainer {
    height: 365px;
  }
}

@media screen and (min-width: 500px) {
  .filterButton {
    font-size: 13px !important;
    height: 70px;
  }
  .newHiresPopupContainer {
    /* margin: -250px 0 0 -250px; */
    margin-left: -250px;
    width: 500px;
  }
  /* .newHiresDataContainer {
        width: 355px;
      } */
  /* .newHireTitle {
    width: 455px;
  } */
  .newHiresLocations {
    top: 126px;
    width: 266px;
    right: 32px;
  }
  .newHireHeadingC1 {
    max-width: calc(100% - 298px);
    vertical-align: top;
  }
  .newHireHeadingC2 {
    width: 285px;
    vertical-align: top;
    padding-top: 10px;
  }
  .newHiresContainer {
    height: 395px;
  }
}

@media (min-width: 600px) {
  .filterButton {
    font-size: 14px !important;
    height: 45px;
  }
  .newHiresPopupContainer {
    margin-left: -300px;
    /* margin: -250px 0 0 -300px; */
    width: 600px;
  }

  /* .newHireTitle {
    width: 555px;
  } */

  .newHiresLocations {
    top: 141px;
    right: 20px;
    width: 266px;
  }
  .newHiresContainer {
    height: 415px;
  }
}
@media screen and (min-width: 700px) {
  .filterButton {
    font-size: 14px !important;
    height: 45px;
  }
  .newHiresPopupContainer {
    margin-left: -350px;
    /* margin: -250px 0 0 -350px; */
    width: 664px;
  }
  /* .newHiresDataContainer {
        width: 555px;
      } */
  /* .newHireTitle {
    width: calc(100% - 5px);
  } */
  .teamMemberImage {
    height: 100px;
  }

  .newHiresHeading {
    /* display: grid;
        grid-template-columns: auto 317px; */
    width: calc(100% - 20px);
    font-family: "nw-primary";
    font-size: 1.1rem;
    margin: 20px 23px 16px 23px;
    position: relative;
    top: 0;
    left: 0;
  }
}

@media screen and (min-width: 800px) {
  /* .newHiresPopupContainer {
        margin-left: -400px;
        width: 800px;
      } */

  /* .newHireTitle {
    width: calc(100% - 5px);
  } */
  .teamMemberImage {
    height: 150px;
  }
}

@media screen and (min-width: 900px) {
  /* .newHiresPopupContainer {
        margin-left: -450px;
        width: 900px;
      } */

  /* .newHireTitle {
    width: 855px;
  } */
}

@media screen and (min-width: 1000px) {
  /* .newHiresPopupContainer {
        margin-left: -500px;
        width: 1000px;
      } */

  /* .newHireTitle {
    width: 955px;
  } */
}

.row-striped:nth-of-type(odd) {
  background-color: #f0eff0;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  border-radius: 12px;
}

.row-striped:nth-of-type(even) {
  background-color: #ffffff;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.filterWindow {
  display: block;
  /* height: 358px; */
  height: calc(100% - 35px);
  overflow-x: hidden;
  overflow-y: auto;
  /* border: black solid 1px; */
  width: 100%;
  /* min-width: 250px; */
  font-size: 14px;
}

.filterWindow::-webkit-scrollbar {
  width: 12px;
}

.filterWindow::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  /* border-radius: 10px; */
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.filterWindow::-webkit-scrollbar-thumb {
  /* border-radius: 10px; */
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background: #1553a5;
}

.filterRow:nth-of-type(even) {
  background-color: #efefef;
}

.filterRow:nth-of-type(odd) {
  background-color: #ffffff;
}

.filterContainer {
  margin: 10px;
  border-color: #1c57a5;
  border-style: solid;
  border-radius: 12px;
  height: calc(100% - 100px);
}
.filterTitleBar {
  background-color: #efefef;
  font-size: 14px;
  font-family: "nw-primary";
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  height: 32px;
  overflow: hidden;
}

.chatButton {
  background-image: url("/chat.svg");
  background-repeat: no-repeat;
  background-position: 20px;
  padding-left: 22px !important;
  padding-top: 1px !important;
  background-color: #daf2fa !important;
  border: 1px solid #44bce4 !important;
  border-radius: 6px;
  height: 24px;
  width: 103px;
  font-family: "nw-primary" !important;
  font-weight: bold !important;
  font-size: 14px !important;
  letter-spacing: 0px;
  color: #1c57a5 !important;
  border-radius: 6px;
  margin-left: 15px;
}
.emailButton {
  background-image: url("/email.svg");
  background-repeat: no-repeat;
  background-position: 15px;
  padding-left: 24px !important;
  height: 24px;
  width: 103px;
  padding-top: 1px !important;
  background-color: #e3f0e0 !important;
  border: 1px solid #72b365 !important;
  border-radius: 6px;
  font-family: "nw-primary" !important;
  font-weight: bold !important;
  font-size: 14px !important;
  letter-spacing: 0px !important;
  color: #1c57a5 !important;
  border-radius: 6px;
  margin-left: 15px;
}
.bravoButton {
  background-image: url("/award-icon.svg");
  background-repeat: no-repeat;
  background-position: 20px;
  padding-left: 28px !important;
  padding-top: 1px !important;
  background-color: #daf2fa !important;
  border: 1px solid #44bce4 !important;
  border-radius: 6px;
  height: 24px;
  width: 103px;
  font-family: "nw-primary" !important;
  font-weight: bold !important;
  font-size: 14px !important;
  letter-spacing: 0px;
  color: #1c57a5 !important;
  border-radius: 6px;
  margin-left: 15px;
  background-size: 18px;
}

.boldLabel {
  color: #4d4f53;
  text-align: left;
  font-family: "nw-primary" !important;
  font-weight: bold !important;
  font-size: 14px !important;
}

.newHiresLinkImage {
  padding-top: 7px;
  background-image: url("/newHires.jpg");
  background-repeat: no-repeat;
  /* background-position: bottom 9px right 39px; */
  background-size: 150px 47px;
}

.newHiresOverlay {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  color: #008cba;
  background-color: white;
  /* font-family: Proxima Nova; */
  font-family: "nw-primary";
  font-size: 14px;
  line-height: 14px;
  padding-left: 5px;
  padding-right: 5px;
}
.newHiresLinkImage:hover .newHiresOverlay {
  opacity: 1;
}

.NewHiresText {
  display: inline-block;
}

.newHiresDepartmentHeading {
  display: inline-block;
  width: calc(100% - 60px);
  color: #1c57a5;
  font-weight: 700;
}

.newHiresDeptContainer {
  display: block;
  border-bottom: #dddedd solid 1px;
  padding-bottom: 10px;
  position: relative;
}

.newHiresToggleButton {
  position: absolute;
  top: 0px;
  border: none;
  background-color: transparent;
  /* margin-left: 10px; */
}

.newHiresContactContainer {
  display: block;
  border-bottom-color: #dddedd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  /* padding-bottom: fs10px;
      margin-bottom: 10px; */
}

.newHireName {
  color: #1c57a5;
  font-size: 18px;
  font-weight: 700;
  padding-left: 10px;
  display: inline-block;
  display: inline-block;
  width: calc(100% - 207px);
  padding-top: 7px;
}

.newHireFilterRow {
  background-color: white;
}
.newHireFilterRow > button {
  text-transform: uppercase;
  width: 100%;
  background-color: transparent;
  text-align: left;
  color: black;
  border: none;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
}
.newHireFilterRow > hr {
  margin-top: 0px;
  margin-bottom: 0px;
  width: 93%;
}
.newHiresWelcomeNote {
  background-image: url("/email.svg");
  background-repeat: no-repeat;
  background-position: 15px;
  padding-left: 24px !important;
  height: 24px;
  width: 225px;
  padding-top: 1px !important;
  background-color: #e3f0e0 !important;
  border: 1px solid #b8d9b2 !important;
  border-radius: 6px;
  font-family: "nw-primary" !important;
  font-weight: bold !important;
  font-size: 14px !important;
  letter-spacing: 0px !important;
  color: #1c57a5 !important;
  border-radius: 6px;
  margin-left: 15px;
}
.newHiresCoffeeTalk {
  background-image: url("/CoffeeCup.png");
  background-repeat: no-repeat;
  background-position: 15px;
  padding-left: 24px !important;
  height: 24px;
  width: 225px;
  padding-top: 1px !important;
  background-color: #f0e7f3 !important;
  border: 1px solid #c5a4d2 !important;
  border-radius: 6px;
  font-family: "nw-primary" !important;
  font-weight: bold !important;
  font-size: 14px !important;
  letter-spacing: 0px !important;
  color: #1c57a5 !important;
  border-radius: 6px;
  margin-left: 15px;
}

.newHiresDeptSeparator {
  color: red;
  background-color: red;
}
