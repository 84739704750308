.myhealthImage {
  width: 97%;
}

.myhealthBlockText {
  display: inline-block;
  position: relative;
  font-size: 12px;
}
.myhealthText25 {
  width: 25%;
}
.myhealthText50 {
  width: 50%;
}

.myHealthCompletion {
  margin-left: 20px;
}
.myHealthWarning {
  background-image: url("/myhealthwarning.png");
  background-repeat: no-repeat;
  font-size: 14px;
  border-color: #237d8a;
  border-style: solid;
  border-width: 1px;
  /* height: 76px; */
  padding-left: 50px;
  width: 97%;
  padding-top: 17px;
  padding-bottom: 17px;
}

#myhealthMissedDeadline {
  background-position-x: -375px;
  top: 96px;
}

.myhealthAwsButtonContainer {
  margin: auto !important;
  width: 112px !important;
  height: 38px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.myhealthButtonContainer1 {
  margin: auto !important;
  width: 160px !important;
  height: 38px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.myhealthButtonContainer2 {
  margin: auto !important;
  width: 95px !important;
  height: 38px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

@media (min-width: 474px) {
  .myhealthButtonContainer1 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .myhealthButtonContainer2 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .myhealthAwsButtonContainer {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
}

@media screen and (max-width: 410px) {
  .MyHealthPortlet {
    padding-bottom: 49px !important;
  }
}

@media (min-width: 576px) {
  .myhealthButtonContainer1 {
    margin-left: 19px !important;
  }
  .myhealthAwsButtonContainer {
    margin-left: 19px !important;
  }
  .myhealthBlockText {
    font-size: 14px;
  }
}

@media (min-width: 433px) {
  #myhealthMissedDeadline {
    background-position-x: -62px !important;
    top: 75px;
  }
}
@media (min-width: 411px) {
  #myhealthMissedDeadline {
    background-position-x: -406px;
  }
}
