.mnuLogoutButton {
  font-family: nw-primary !important;
  font-size: 21px !important;
  font-weight: bold !important;
  color: white !important;
  margin-top: 0px;
}

.menu {
  height: 22px;
  text-align: left;
  /* font: Bold 18px/22px Proxima Nova; */
  font-family: "nw-primary";

  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  display: inline-block;
  margin-top: 0px;
  /* margin-left: 42px; */
}

.menuMobileDisplay {
  display: inline-block !important;
}

.menuMobileExpanded {
  min-height: 1000px;
  height: 100%;
}
.menuDesktopDisplay {
  display: none !important;
}

.dropdown-toggle {
  border: none !important;
}

.active-menu {
  /* background-image: url("/active_chevron_down.png");
  background-repeat: no-repeat;
  background-position: bottom 0px right 10px;
  background-size: 12px 6px; */
  padding-right: 30px;
  height: 100%;
  background-color: #ffffff !important;
}

.inactive-menu {
  /* background-image: url("/white_chevron_down.png");
  background-repeat: no-repeat;
  background-position: bottom 0px right 10px;
  background-size: 12px 6px; */
  padding-right: 30px;
  height: 35px;
}

.svgwhite {
  fill: white;
}

.menuDisplayed {
  background-image: url("/icon-close.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  height: 24px;
  width: 24px;
}

.menuClosed {
  background-image: url("/icon-menu.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  height: 24px;
  width: 24px;
}
.showInlineWhenMobile {
  display: inline-block;
}
.showWhenMobile {
  display: block;
}

.showWhenMobile:focus {
  outline: none !important;
}

.hideWhenMobile {
  display: none;
}
.mobileMenu {
  height: 100%;
  display: block !important;
  font-family: nw-primary;
  font-size: 21px !important;
  text-align: left !important;
  width: 100%;
  border-bottom-style: solid !important;
  border-bottom-width: 1px !important;
  border-bottom-color: #3fcdd7 !important;
  font-weight: bold !important;
  /* padding-left: 30px; */
}

.mobileMenu:focus {
  outline: none !important;
}

.activeMobileMenu {
  color: #003b5c !important;
  background-color: #3fcdd7 !important;
}
.inactiveMobileMenu {
  color: #ffffff !important;
  background-color: #007d8a !important;
}
#careerMenu {
  transition-duration: 0.3s;
  transition-property: transform;
}
#payAndBenefitsMenu {
  transition-duration: 0.3s;
  transition-property: transform;
}
#peopleandcultureMenu {
  transition-duration: 0.3s;
  transition-property: transform;
}
/* #payAndBenefitsMenu:hover {
  transform: rotate(360deg);
} */

.activeMobileMenuImage {
  /* display: inline-block; */
  float: right;

  transform: rotate(180deg);
}
.inactiveMobileMenuImage {
  /* display: inline-block; */
  float: right;
  transform: none;
}
.blockHideWhenMobile {
  display: none;
}
@media (min-width: 576px) {
  .hideWhenMobile {
    display: inline-block;
  }

  .blockHideWhenMobile {
    display: block;
  }
  .showWhenMobile {
    display: none;
  }
  .showInlineWhenMobile {
    display: none;
  }

  .menuMobileDisplay {
    display: none !important;
  }
  .menuDesktopDisplay {
    display: inline-block !important;
  }
  /* .mnuLogoutButton {
      margin-top: 44px;
    } */

  .menu {
    margin-top: 44px;
    height: 100%;
    /* margin-left: 42px; */
  }

  .active-menu {
    background-position: bottom 12px right 10px;
  }

  .inactive-menu {
    background-position: bottom 12px right 10px;
    height: 100%;
  }

  .mnuLogoutButton {
    font-family: nw-primary !important;
    font-size: 18px !important;
    font-weight: bold !important;
    color: white !important;
    margin-top: 0px;
  }
}

.mobileExpandedContainer {
  display: block;
  min-width: 265px;
  background-color: #3fcdd7;
  padding-bottom: 20px;
}

.mobileExpandedItemsContainer {
  display: block;
  min-width: 265px;
  background-color: #3fcdd7;
  font-size: 20px;
  padding-left: 10px;
  margin-left: 10px;
  border-left-color: white;
  border-left-style: solid;
  border-left-width: 1px;
}

.mobileCollapsedContainer {
  display: none;
}

.mobileMenuLink {
  color: #003b5c;
  text-decoration: none;
}
#donotshowfocus:focus {
  outline: none !important;
}
.menuLinkContainer {
  padding-bottom: 10px;
}
