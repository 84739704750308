.mobilearrow{
    /* width: 13px; */
    /* height: 11px; */
    /* transform: matrix(-1, 0, 0, -1, 0, 0); */
    /* background: #707070 0% 0% no-repeat padding-box;
    opacity: 1; */
    width: 13px;
    height: 13px;
    margin-top: 10px;
    margin-right: 10px;
    border: solid #707070;
    border-width: 0px 3px 3px 0;
    display: inline-block;
    padding: 3px;
    }

.mobile-arrowdown {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.dropdown-mobilebar{
  border: 1px solid #707070;
  font-family: "nw-primary";
  height: 37px;
  background-color:  #EFEFEF;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
}

.dropdown-mobilesearch{
    width: 170px;
    background: 0% 0% no-repeat padding-box;
    background-color:  #EFEFEF !important;
    border-radius: 12px;
    opacity: 1;
    height: 35px;
    font-family: "nw-primary" !important;
    color: #000 !important;
    text-align: left !important;
    padding-left: 15px !important;
  }

.searchbar-mobile{
    background: #DEE6F2 0% 0% no-repeat padding-box;
    opacity: 1;
    /* width: 100%; */
    height: 67px;
  }
