.tile-box-benefits {
  border: 1px solid;
  border-color: #707070;
  border-radius: 11px;
  opacity: 1;
  width: 185px;
  height: 48px;
  margin-top: 10px;
  margin-right: 15px;
  margin-left: 10px !important;
}

.tile-box-benefits-final {
  border: 1px solid;
  border-color: #707070;
  border-radius: 11px;
  opacity: 1;
  width: 95%;
  height: 75px;
  margin-top: 10px;
  margin-right: 15px;
  margin-left: 10px !important;
}

.tile-infobox-benefits {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #237d8a;
  opacity: 1;
  width: 95%;
  min-height: 55px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 10px;
}

.tile-infosubbox-benefits {
  max-width: 34px;
  height: 54px;
  background: #237d8a 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-left: 10px;
}

.tile-infobox-infoicon {
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  font-family: "nw-primary";
  font-size: 14px;
  text-align: center;
  margin-left: -8px;
  margin-right: 10px;
  margin-top: 10px;
  color: #237d8a;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  font-weight: bolder;
}

.myBenefitsWarning {
  background-image: url("/myhealthwarning.png");
  background-repeat: no-repeat;
  font-size: 14px;
  border-color: #237d8a;
  border-style: solid;
  border-width: 1px;
  /* height: 76px; */
  padding-left: 50px !important;
  width: 97%;
  padding-top: 17px;
  padding-bottom: 17px;
}

.tile-benefits-SeperatorLine {
  border: 1px solid #bcbdbc;
  width: 0px;
  height: 50px;
  margin-left: -15px;
  margin-top: 10px;
  margin-right: 9px;
}

@media (min-width: 576px) {
  .tile-box-benefits-final {
    width: 445px;
  }
  .tile-infobox-benefits {
    width: 445px;
  }
}

#benefitsInfo {
  background-position-x: -152px !important;
  top: 181px;
  width: 92%;
}

@media (min-width: 370px) {
  #benefitsInfo {
    background-position-x: -142px !important;
  }
}
@media (min-width: 380px) {
  #benefitsInfo {
    background-position-x: -132px !important;
  }
}
@media (min-width: 390px) {
  #benefitsInfo {
    background-position-x: -122px !important;
  }
}
@media (min-width: 400px) {
  #benefitsInfo {
    background-position-x: -112px !important;
  }
}
@media (min-width: 410px) {
  #benefitsInfo {
    background-position-x: -102px !important;
  }
}

@media (min-width: 420px) {
  #benefitsInfo {
    background-position-x: -92px !important;
    top: 112px;
  }
}
@media (min-width: 430px) {
  #benefitsInfo {
    background-position-x: -82px !important;
  }
}
@media (min-width: 440px) {
  #benefitsInfo {
    background-position-x: -72px !important;
  }
}
@media (min-width: 450px) {
  #benefitsInfo {
    background-position-x: -62px !important;
  }
}
@media (min-width: 460px) {
  #benefitsInfo {
    background-position-x: -52px !important;
  }
}
@media (min-width: 470px) {
  #benefitsInfo {
    background-position-x: -42px !important;
  }
}

@media (min-width: 485px) {
  #benefitsInfo {
    background-position-x: -27px !important;
  }
}
@media (min-width: 500px) {
  #benefitsInfo {
    background-position-x: 0px !important;
  }
}
@media (min-width: 575px) {
  #benefitsInfo {
    top: 120px;
  }
}
#benefitsInfoLink {
  color: #1c57a5;
  margin-top: 16px !important;
  float: right;
}
@media (min-width: 420px) {
  #benefitsInfoLink {
    margin-top: -2px !important;
  }
}
@media (min-width: 471px) {
  #benefitsInfoLink {
    margin-top: 16px !important;
  }
}

.benefitPlansHeader {
  margin-left: 10px !important;
  margin-right: 0px !important;
  margin-top: 10px !important;
  border-top: 1px solid rgb(188, 189, 188);
  border-bottom: 1px solid rgb(188, 189, 188);
}
.benefitPlansFooter {
  margin-left: 10px !important;
  margin-right: 0px !important;
  border-top: 1px solid rgb(188, 189, 188);
  font-size: 14px;
  line-height: 15px;
  padding-top: 15px;
}

.benefitPlanTypeHead {
  color: rgb(28, 92, 185);
  font-size: 11px;
  font-weight: bold;
  background-color: rgb(222, 230, 242);
}

.benefitCoverageHead {
  color: #1c5cb9;
  font-size: 11px;
  font-weight: bold;
}

.benefitPlansRow {
  margin-left: 10px !important;
  margin-right: 0px !important;
}
.benefitPlanType {
  color: #4d4f53;
  font-size: 14px;
  background-color: rgb(222, 230, 242);
  border-bottom: 1px solid white;
}
.benefitPlanCoverage {
  color: #4d4f53;
  font-size: 14px;
  background-color: white;
  font-weight: bold;
  border-bottom: 1px solid #dee6f2;
}
