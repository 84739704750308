.linksbar {
  /* background-image: url(../img/repeating-pattern-tools.jpg); */
  background-image: url("/repeating-pattern-tools.jpg");
  width: 100%;
  background-repeat: repeat;
  min-height: 70px;
  overflow-y: hidden;
  overflow-x: auto;
}

.linksbar .container {
  min-width: 1215px !important;
}

.linkButton {
  top: 33px;
  left: 120px;
  width: 160px;
  height: 49px;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 10;
  border-radius: 3px;
  border-color: white;
  display: inline-block;
  margin-top: 10px;
  margin-right: 5px;
  margin-left: 5px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}

.wdButton {
  color: #086eae;
  /* font: Proxima Nova; */
  font-family: "nw-primary";
  font-size: 18px;
  /* background-image: url(../img/workday.jpg); */
  background-image: url("/wdaynew.png");
  background-size: 74px 30px;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 80px;
}

@media (min-width: 420px) {
  .linksbar {
    /* background-image: url("/repeating-pattern-tools.jpg");*/
    width: 100%;
    background-repeat: repeat;

    overflow-x: unset;
    overflow-y: unset;
  }
  .linksbar .container {
    min-width: 100% !important;
  }
  .linkButton {
    margin-top: 33px;
    margin-right: 3px;
    /*margin-left: 40px;*/
  }
}

@media (min-width: 500px) {
  .linksmargin {
    margin-left: -px;
  }
  .linksbar {
    /* height: 339px; */
    text-align: center;
  }
}

@media (min-width: 1055px) {
  .linksbar {
    background-image: url("/repeating-pattern-tools.jpg");
    width: 100%;
    background-repeat: repeat;
    min-height: 109px;
    overflow-x: unset;
    overflow-y: unset;
  }
}

@media (min-width: 1200px) {
  .linksmargin {
    margin-left: -35px;
  }
}

.workdayLinkImage {
  /* width: 150px;
  height: 55px; */
  padding-top: 7px;
  background-image: url("/workday.jpg");
  background-repeat: no-repeat;
  /* background-position: bottom 9px right 39px; */
  background-size: 115px 47px;
  background-position: center;
}

.workdayOverlay {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  color: #008cba;
  background-color: white;
  /* font-family: Proxima Nova; */
  font-family: "nw-primary";
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
}

.workdayLinkImage:hover .workdayOverlay {
  opacity: 1;
}

.bravoLinkImage {
  /* width: 82px; */
  padding-top: 7px;
  background-image: url("/bravo.jpg");
  background-repeat: no-repeat;
  /* background-position: bottom 9px right 39px; */
  background-size: 150px 47px;
  background-position: center;
}

.bravoOverlay {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  color: #008cba;
  background-color: white;
  /* font-family: Proxima Nova; */
  font-family: "nw-primary";
  font-size: 10px;
  line-height: 12px;
  padding-left: 5px;
  padding-right: 5px;
}
.bravoLinkImage:hover .bravoOverlay {
  opacity: 1;
}

.fowLinkImage {
  /* width: 82px; */
  /* width: 149px;
  height: 32px; */
  padding-top: 7px;
  background-image: url("/FoWnew.png");
  background-repeat: no-repeat;
  /* background-position: bottom 9px right 39px; */
  background-size: 150px 47px;
  background-position: center;
}

.fowOverlay {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  color: #008cba;
  background-color: white;
  /* font-family: Proxima Nova; */
  font-family: "nw-primary";
  font-size: 16px;
  line-height: 30px;
  padding-left: 5px;
  padding-right: 5px;
}
.fowLinkImage:hover .fowOverlay {
  opacity: 1;
}

.learningLink {
  /* font-family: Proxima Nova; */
  font-family: "nw-primary";
  font-size: 11px;
  color: #1c57a5;
  padding-top: 7px;
  line-height: 12px;
}
.idriveLinkImage {
  padding-top: 7px;
  background-image: url("/workday-learning.png");
  background-repeat: no-repeat;
  /* background-position: bottom 9px right 39px; */
  background-size: 150px 47px;
  background-position: center;
}
.idriveOverlay {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  color: #008cba;
  background-color: white;
  /* font-family: Proxima Nova; */
  font-family: "nw-primary";
  font-size: 13px;
  line-height: 14px;
  padding-left: 5px;
  padding-right: 5px;
}
.idriveLinkImage:hover .idriveOverlay {
  opacity: 1;
}

.awsLinkImage {
  padding-top: 7px;
  background-image: url("/wellbeing.jpg");
  background-repeat: no-repeat;
  /* background-position: bottom 9px right 39px; */
  background-size: 120px 40px;
  background-position: center;
}
.awsOverlay {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  color: #008cba;
  background-color: white;
  /* font-family: Proxima Nova; */
  font-family: "nw-primary";
  font-size: 10px;
  line-height: 12px;
  padding-left: 5px;
  padding-right: 5px;
}
.awsLinkImage:hover .awsOverlay {
  opacity: 1;
}

/* .insideLinkImage {
  padding-top: 12px;
  background-image: url("/BeHeard.svg");
  background-repeat: no-repeat;
  background-position-y: center;
  background-size: 40px 35px;
  text-align: center;
  font-family: "nw-primary";
  font-size: 16px;
  font-weight: 600;
  color: #1c57a5;
  padding-left: 8px;
} */

.anytimeFeedbackLinkImage {
  padding-top: 7px;
  background-image: url("/AnytimeFeedback.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 135px 55px;
}

.anytimeFeedbackOverlay {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  color: #008cba;
  background-color: white;
  /* font-family: Proxima Nova; */
  font-family: "nw-primary";
  font-size: 10px;
  line-height: 11px;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: -3px;
}
.anytimeFeedbackLinkImage:hover .anytimeFeedbackOverlay {
  opacity: 1;
}

.devCalendarLinkImage {
  padding-top: 7px;
  background-image: url("/dev-opp-cal.png");
  background-repeat: no-repeat;
  /* background-position: bottom 9px right 39px; */
  background-size: 150px 50px;
  background-position: center;
}
.devCalendarOverlay {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  color: #008cba;
  background-color: white;
  /* font-family: Proxima Nova; */
  font-family: "nw-primary";
  font-size: 13px;
  line-height: 14px;
  padding-left: 5px;
  padding-right: 5px;
}
.devCalendarLinkImage:hover .devCalendarOverlay {
  opacity: 1;
}
