.tile-header {
  color: #007d8a;
  font-size: 20px;
  text-align: left;
  letter-spacing: 0px;
  padding-left: 25px;
  padding-top: 10px;
  /* padding-bottom: 9.23px; */
  font-family: "nw-primary";
}

.tile-line {
  height: 0px;
  width: 96%;
  margin-top: 0px;
  border: 1px solid #bcbdbc;
  margin-bottom: 10px;
  max-width: 445px;
}

.org-line {
  height: 0px;
  width: 96%;
  margin-top: 0px;
  border: 1px solid #bcbdbc;
  margin-bottom: 10px;
}

.org-line2 {
  height: 0px;
  width: 92%;
  margin-top: 0px;
  border: 1px dotted #bcbdbc;
  margin-bottom: 10px;
  margin-top: 10px;
  /* padding-left: -px; */
}

.tile-refresh {
  display: block;
  position: absolute;
  top: 12px;
  right: 50px;
  cursor: pointer;
  font-family: "nw-primary";
}

.tile-blur {
  display: block;
  position: absolute;
  /* top: 16px; */
  top: 12px;
  right: 18px;
  cursor: pointer;
}

.tile-subbox2 {
  background: #dee6f2 0% 0% no-repeat padding-box;
  /* width: 479px; */
  min-height: 54px;
  margin-left: 5.65px;
  /* margin-top: 10px; - add it based on the tile */
  margin-right: 5.46px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  display: flex;
  flex-direction: row;
}

.tile-subbox {
  background: #ffffff 0% 0% no-repeat padding-box;
  /* width: 479px; */
  min-height: 54px;
  margin-left: 5.65px;
  /* margin-top: 10px; - add it based on the tile */
  margin-right: 5.46px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  display: flex;
  flex-direction: row;
}

.tile-rectangle {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #1c57a5;
  border-radius: 7px;
  opacity: 1;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.tile-rectangle2 {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #1c57a5;
  border-radius: 3px;
  opacity: 1;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
.tile-recttitle {
  text-align: left;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 0px;
  color: #1c57a5;
  opacity: 1;
  display: inline-block;
  padding-left: 6px;
  line-height: 17px;
  padding-top: 10px;
  font-family: "nw-primary";
}

.tile-externallink {
  background: 0% 0% no-repeat padding-box;
  opacity: 1;
  height: 12px;
  width: 12px;
  margin-left: 15px;
}

.tile-portletDate {
  float: left;
  text-align: left;
  font-size: 11px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  padding-left: 23px;
  padding-top: 8px;
}

.tile-apierroricon {
  text-align: center;
  padding-top: 17px;
  padding-bottom: 17px;
  /* padding-left: 230px; */
}

.tile-apierrortext {
  text-align: center;
  font-size: 20px;
  letter-spacing: 0px;
  color: #4d4f53;
  opacity: 1;
  font-family: "nw-primary";
}

.tile-apierrortext1 {
  text-align: center;
  font-size: 12px;
  letter-spacing: 0px;
  color: #4d4f53;
  opacity: 1;
  font-family: "nw-primary";
}

.tile-apierrorcontactus {
  text-align: center;
  font-size: 12px;
  letter-spacing: 0px;
  color: #4d4f53;
  opacity: 1;
  font-family: "nw-primary";
  text-decoration: underline;
  cursor: pointer;
}

.tile-apierror-refresh {
  font-family: "nw-primary";
  text-align: center;
  text-decoration: underline;
  font-size: 12px;
  cursor: pointer;
  color: #d32426;
  padding-top: 18px;
}

.tile-apierror {
  /* margin-top: 10px; - should be based on the tile */
  display: flex;
  flex-direction: row;
  margin-left: 10px;
}

.tile-apierroremoji {
  background: #d32426 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 38px;
  height: 36px;
}

.tile-apierrorbox {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d32426;
  opacity: 1;
  width: 215px;
  height: 46px;
  color: #d32426;
  margin-top: 8px;
  padding-top: 8px;
  letter-spacing: 0px;
  font-size: 12px;
}

.tile-values {
  font-weight: bold;
  text-align: left;
  letter-spacing: 0px;
  color: #1c57a5;
  display: inline-table;
  font-family: "nw-primary";
}

.tile-title {
  font-weight: bold;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #4d4f53;
  font-family: "nw-primary";
}

.tile-title2 {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #4d4f53;
  font-family: "nw-primary";
}

.tile-hours {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #4d4f53;
  font-family: "nw-primary";
}

.pColumn {
  display: inline-block;
  max-width: 495px;
  width: 100%;
  vertical-align: top;
}

.pSubColumn {
  display: block;
  width: 100%;
  /* padding-left: 10px;
  padding-right: 10px; */
  position: relative;
}

.portletContainer {
  border-radius: 12px 12px 12px 12px;
  background-color: #206eb6;
  max-width: 495px;
  width: 100%;
  min-height: 195px;
  display: block;
  /* align-self: self-end; */
  padding: 3px;
  margin-top: 20px;
}

.portletContainerCollapsed {
  border-radius: 12px 12px 12px 12px;
  background-color: #206eb6;
  max-width: 495px;
  width: 100%;
  /* min-height: 195px; */
  display: block;
  /* align-self: self-end; */
  padding: 3px;
  margin-top: 20px;
}

.portletContent {
  border-radius: 12px 12px 12px 12px;
  background-color: white;
  /* width: 489px; */
  /* height: 100%; */
  min-height: 189px;
  /* margin: 3px; */
}
.portletContentCollapsed {
  border-radius: 12px 12px 12px 12px;
  background-color: white;
  /* width: 489px; */
  /* height: 100%; */
  /* min-height: 189px; */
  /* margin: 3px; */
}
.tileTwocolumn {
  width: 100% !important;
}

.tileThreecolumn {
  width: 100% !important;
  display: block !important;
}
@media (min-width: 576px) {
  .tileTwocolumn {
    width: 50% !important;
  }

  .tileThreecolumn {
    width: 33% !important;
  }
  .tile-line {
    height: 0px;
    width: 200%;
    margin-top: 0px;
    border: 1px solid #bcbdbc;
    margin-bottom: 10px;
    max-width: 445px;
  }
}

.tile-benefits {
  color: #007d8a;
  font-size: 17px;
  text-align: left;
  letter-spacing: 0px;
  padding-left: 25px;
  padding-top: 10px;
  /* padding-bottom: 9.23px; */
  font-family: "nw-primary";
}
