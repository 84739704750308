.awsButtonContainer {
  margin: auto !important;
  width: 240px !important;
  height: 38px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

@media (min-width: 576px) {
  .awsButtonContainer {
    margin-left: 19px !important;
  }
}
