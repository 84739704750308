.bolt-background-blue-dark {
  background-color: #0b3a5d;
}

.topStrip {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 37px;
  position: relative;
  /* min-width: 1280px; */
  opacity: 1;
}

.topColor {
  background: #1a2a36 0% 0% no-repeat padding-box;
}

.welcomediv {
  top: 9px;
  height: 17px;
  font-family: "nw-primary";
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  /* padding-left: 938px; */
  padding-top: 7px;
  white-space: nowrap;
}
.header {
  top: -1px;
  left: 0px;
  width: 100%;
  /* min-width: 1280px; */
  min-height: 106px;
  background: #007d8a 0% 0% no-repeat padding-box;
  opacity: 1;
}

.nwlogo {
  width: 60px;
  height: 60px;
  background-image: url("/NandEagle-Vert-NW-Rev.png");
  /* background-image: url(../img/NandEagle-Vert-NW-Rev.png); */
  background-size: 60px 60px;
  background-repeat: no-repeat;
  display: inline-block;
  margin-top: 17px;
}

.hrlogo {
  width: 103px;
  height: 39px;
  /* background-image: url("/myHR-portal-logo.png"); */
  background-image: url("/HRConnect.png");
  /* background-image: url(../img/myHR-portal-logo.png); */
  background-size: 103px 25px;
  background-repeat: no-repeat;
  opacity: 1;
  display: inline-block;
  margin-top: 28px;
}

.searchFieldContainer {
  margin-top: 40px;
  display: inline-block;
  /* margin-left: 25px; */
}

.snComponentTextInputMSearchPage {
  display: inline-block;
  font-family: "nw-primary";
  height: 40px;
  width: 75%;
  font-size: 14px;
  color: #333;
  border: 2px solid #e2e3e5;
  border-top: none;
  border-left: none;
  padding: 0.375rem 0.75rem;
  border-radius: 12px 0px 0px 12px;
}

.snComponentTextInput {
  display: inline-block;
  font-family: "nw-primary";
  height: 40px;
  width: 88%;
  font-size: 14px;
  color: #333;
  border: 2px solid #e2e3e5;
  border-top: none;
  border-left: none;
  padding: 0.375rem 0.75rem;
  border-radius: 12px 0px 0px 12px;
}
@media (min-width: 576px) {
  .snComponentTextInput {
    width: 265px;
  }
  .snComponentTextInputMSearchPage {
    width: 265px;
  }
  .footerText {
    margin-top: 0px !important;
  }
}
.snComponentButton {
  /* background-color: #fff; */
  height: 40px;
  border: none;
  border-bottom: 2px solid #0b3a5d;
  border-radius: 0px 12px 12px 0px;
}

.fl {
  /* float: left; */
  left: 0px;
}

.fr {
  /* float: right; */
  right: 0px;
}

.portalsbar {
  background-color: #dee6f2;
  width: 100%;
  min-height: 215px;
  padding-bottom: 20px;
}

.footer {
  background-color: #003b5c;
  color: white;
  min-height: 106px;
}

.WorkdayPortlet {
  padding-bottom: 30px !important;
}

.wdPortletFooter {
  float: right;
  padding-right: 12px;
}

.footerLink {
  /* font-family: Proxima Nova, Regular; */
  font-family: "nw-primary";
  font-size: 14px;
  color: white;
  text-decoration: underline;
}

.footerLink:hover {
  text-decoration: underline;
  color: white;
}

.footerText {
  /* font-family: Proxima Nova, Regular; */
  font-family: "nw-primary";
  font-size: 11px;
  line-height: 12px;
  margin-top: 12px;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #1c57a5 !important;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  /* font-family: Proxima Nova; */
  font-family: "nw-primary";
  font-size: 17px;
  font-weight: bold;
}
.dropdown-item-button button {
  background-color: transparent;
  /* padding: 0px !important; */
  padding-right: 0px !important;
  /* color: #237D8A!important; */
  /* font-family: Proxima Nova; */
  font-family: "nw-primary";
  font-size: 17px;
  font-weight: bold;
}

@media screen and (max-width: 1182px) {
  .topStrip {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 35px;
    /* min-width: 1280px; */
    opacity: 1;
  }
}

@media screen and (min-width: 576px) {
}

.dropable {
  aborder-radius: 12px 12px 12px 12px;
  aborder-style: dashed;
  aborder-color: grey;
  aborder-width: 1px;
  width: 329px;
  height: 50px;
  display: inline-block;
  apadding-left: 5px;
  apadding-top: 5px;
  overflow: hidden;
}
.availablePlace {
  background-image: url("/EmptyPortalPlaceholder_small.png");
  background-repeat: no-repeat;
}
.availableActivePlace {
  background-image: url("/EmptyPortalPlaceholderActive_small.png");
  background-repeat: no-repeat;
}
.candrag {
  border-radius: 12px 12px 12px 12px;
  border-style: solid;
  border-color: grey;
  border-width: 1px;
  display: inline-block;
  width: 329px;
  height: 50px;
  background-color: white;
  apadding-top: 23px;
  color: #007d8a;
  font-size: 16px;
  font-family: nw-primary;
  cursor: move;
}
.added {
  background-image: url("/sixdot_small.png");
  background-repeat: no-repeat;
}
.notadded button:first-child {
  background-image: url("/addportal.png");
  background-repeat: no-repeat;
  background-position-y: center;
}
.added button:first-child {
  background-image: url("/remove.png");
  background-repeat: no-repeat;
  background-position-y: center;
}
.addremportalbtn {
  width: 28px;
  background-color: transparent;
  border: none;
  position: relative;
  top: 9px;
  left: 0px;
  height: 28px;
}

.customizeInstrHead {
  color: #0072cf;
  font-size: 16pt;
  text-align: right;
  margin: 5px;
  min-width: 115px;
  margin-top: 50px;
}
.portletDesignContainer {
  background-color: white;
  margin-top: 20px;
  padding: 10px;
  border-radius: 12px;
  box-shadow: 5px 5px;
}
.Tile {
  /* background-image: url("/Tile.png"); */
  background-repeat: no-repeat;
  width: 489px;
  height: 43px;
  margin: auto;
  /* padding-top: 20px; */
  margin-top: 20px;
  text-align: center;
  border-style: dashed;
  border-radius: 12px;
  border-color: lightgray;
}

.search-suggestion {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000093;
  border-radius: 6px;
  opacity: 1;
  font-family: "nw-primary";
  text-align: left;
  letter-spacing: 0px;
  color: #4d4f53;
  opacity: 1;
  margin-bottom: 10px;
  min-width: 302px;
  position: absolute;
  z-index: 9;
}

.search-suggestionMsearch {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000093;
  border-radius: 6px;
  opacity: 1;
  font-family: "nw-primary";
  text-align: left;
  letter-spacing: 0px;
  color: #4d4f53;
  opacity: 1;
  margin-bottom: 10px;
  min-width: 75%;
  position: absolute;
  z-index: 9;
}

.suggestiontitle {
  border-bottom: 1px solid #bcbdbc;
  opacity: 1;
  padding: 8px;
  font-size: 14px;
}

.suggestiontitle:hover {
  background-color: lightgray;
}

.search-apierrortext1 {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #4d4f53;
  opacity: 1;
  font-family: "nw-primary";
  margin-left: 95px;
  text-align: "left";
  padding-top: "5px";
}

.formInputBox {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 5px;
  opacity: 1;
  display: block;
}

.AdvancedSearchText {
  text-align: left;
  font-size: 14px;
  font: "nw-primary";
  letter-spacing: 0px;
  color: #4d4f53;
  opacity: 1;
  padding-bottom: 7px;
}

.ASButton {
  display: block;
  width: 112px;
  height: 36px;
  background: #1c57a5 0% 0% no-repeat padding-box;
  border-radius: 7px;
  opacity: 1;
  margin-bottom: 20px;
}

.ASClearbutton {
  margin-left: 250px;
  width: 106px;
  height: 39px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 7px;
  border-color: #1c57a5;
  opacity: 1;
  margin-bottom: 20px;
  margin-right: 39px;
}

.disclaimerClearbutton {
  margin-left: 200px;
  margin-top: 20px;
  width: 80px;
  height: 39px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 7px;
  border-color: #1c57a5;
  opacity: 1;
  margin-bottom: 20px;
  margin-right: 20px;
}

.disclaimerDownload {
  /* margin-left: 200px; */
  border-radius: 7px;
  margin-top: 20px;
  width: 134px;
  height: 39px;
  background: #1C57A5 0% 0% no-repeat padding-box;
  opacity: 1;
}

.searchButtonText {
  width: 44px;
  height: 17px;
  font-family: "nw-primary";
  font: normal normal bold 14px/17px;
  letter-spacing: 0px;
  color: #fbfbfb;
  opacity: 1;
  padding-top: -4px;
}

.ASBackgroundTop {
  top: 128px;
  left: 114px;
  width: 1053px;
  height: 40px;
  background: var(---bolt-color-white) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
}

.ASLabel {
  font-family: "nw-primary";
  font-size: 14px;
  text-align: left;
  letter-spacing: 0px;
  color: #4d4f53;
  opacity: 1;
  /* padding-top: 7px; */
}

.ASTips {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #1c57a580;
  border-radius: 15px;
  opacity: 1;
  width: 409px;
}

.APSDataExtract {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #1c57a580;
  border-radius: 15px;
  opacity: 1;
  width: 409px;
  margin-top: 15px;
  padding-bottom: 5px;
}

.ASBackground {
  background-color: #dee6f2;
  width: 100%;
  min-height: 225px;
  padding-bottom: 20px;
}

.APShelp {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #1c57a580;
  border-radius: 15px;
  opacity: 1;
}

.APSHelpTitle {
  text-align: left;
  float: left;
  font: 20px/24px;
  font-family: "nw-primary";
  letter-spacing: 0px;
  color: #007d8a;
  opacity: 1;
  padding-left: 25px;
  padding-top: 20px;
  opacity: 1;
}

.APSHelpLine {
  border-bottom-width: 1px;
  border-bottom-color: #bcbdbc;
  border-bottom-style: solid;
  padding-top: 50px;
  margin-left: 25px;
  width: 360px;
}

.APSHelpText {
  text-align: left;
  letter-spacing: 0px;
  color: #4d4f53;
  opacity: 1;
  margin-top: 10px;
  padding-left: 25px;
  font-size: 14px;
  margin-right: 13px;
}

.APSHelplink {
  text-align: left;
  text-decoration: underline;
  letter-spacing: 0px;
  color: #1c57a5;
}

.APSannouncementContainer {
  border-radius: 12px 12px 0px 0px;
  background-color: white;
  /* max-width: 1068px;
    min-width: 1004px; */
  display: inline-block;
  overflow: hidden;
  text-align: center;
}

@media screen and (max-width: 410px) {
  .NSPPortlet {
    padding-bottom: 49px !important;
  }
}
@media screen and (max-width: 310px) {
  .WorkdayPortlet {
    padding-bottom: 49px !important;
  }
}

.leaderConnectButton {
  font-size: 17px;
  color: white !important;
  /* box-shadow: 1px 1px 20px 11px #0ff; */
  height: 30px;
  margin-top: 5px;
  /* margin-left: 50%; */
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  background-color: #1c57a5 !important;
}
.leaderConnectButton:hover {
  text-decoration: none !important;
}
